import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export const PostImage = ({src, alt, width}) => {
  const {allWordpressWpMedia} = useStaticQuery(allMedia);
  const originalSource = src.replace(/^(http?s:\/\/.+?\/.+?)-(\d+x\d+)\.(.+?)$/g, '$1.$3');
  const image = allWordpressWpMedia.edges.find(({node}) => node.source_url === originalSource);
  return !image || !image.node.localFile.childImageSharp ? (
    <img
      src={src}
      alt={alt}
      style={{width: width ? width : '100%'}}/>
  ) : (
    <Img
      fluid={image.node.localFile.childImageSharp.fluid}
      alt={alt}
      style={{
        width: width ? width + 'px' : '100%',
        maxWidth: '100%'
      }}/>
  );
};

const allMedia = graphql`
  query {
    allWpMediaItem {
      nodes {
        sourceUrl
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 800) {
              src
            }
          }
        }
      }
    }
  }
`;
