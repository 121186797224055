import React from "react"
import parse from 'html-react-parser'
import { PostImage } from "./postImage"

const PostContent = ({className, content}) => {
  const getImage = node => {
    if (node.name === 'img') {
      return node
    } else if (node.children != null) {
      for (let index = 0; index < node.children.length; index++) {
        const image = getImage(node.children[index])

        if (image) {
          return image
        }
      }
    }
  }

  const replaceMedia = node => {
    if (node.name === 'p') {
      const image = getImage(node);

      if (image) {
        return <PostImage src={image.attribs.src} alt={image.attribs.alt} width={image.attribs.width} />
      }
    }
  }

  return (
    <div className={className}>{parse(content || "", {replace: replaceMedia})}</div>
  )
}

export default PostContent
